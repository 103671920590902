// 3rd party
import Link from 'next/link'
import { navClick } from '@/lib/ga-events'
// Helpers
import { formatUrlSlug } from '@/lib/helper'

const TopLevelLinks = ({ topLevelLinks }) => (
  <ul className="flex flex-row items-center gap-[48px] h-[68px]">
    {topLevelLinks?.items?.map((link, i) => (
      <li key={`top-menu-link-${link.title}-${i}`} onClick={() => navClick(formatUrlSlug(link.link), link.title, 'top_level', link.title)}>
        <Link href={formatUrlSlug(link.link)}>
          <a
            className={`text-center xl:max-w-none max-w-[120px] break-words uppercase font-semibold xl:text-[13px] text-[12px] leading-[16.25px] 
                  tracking-[0.2em] h-full flex items-center cursor-pointer transition-all underline hover:decoration-white decoration-2 decoration-transparent underline-offset-4 text-white`}
          >
            {link.title}
          </a>
        </Link>
      </li>
    ))}
  </ul>
)

export default TopLevelLinks
